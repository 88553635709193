import * as React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Card} from "react-bootstrap";
import PricingButton from "./components/PricingButton";
import {Helmet} from "react-helmet";
import {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import API from "./api";
import Badge from 'react-bootstrap/Badge';
import Spinner from "react-bootstrap/Spinner";
import Accordion from "react-bootstrap/Accordion";
import Stack from "react-bootstrap/Stack";
import PricingCarousel from "./components/PricingCarousel";

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

function InstructorPricing() {
	const [plans, setPlans] = useState()
	let query = useQuery();
	let { instructorId } = useParams();
	const [isLoading, setLoading] = useState(false)
	const navigate = useNavigate();

	useEffect(() => {
		setTimeout(() => {
			setLoading(true);
			API.get(`plans/instructor/${instructorId}`)
				.then(response => {
					if (response.data.instructor === null || !response.data.plans.length) {
						navigate("/dna-profile", { replace: true });
					}
					setPlans(response.data);
					return response.data;
				})
				.finally(() => setLoading(false));



		}, 0);
	}, [instructorId])


	const cadenceMap = {
		'MONTHLY': 'month',
		'ANNUAL': 'annual',
	}

	function insertDecimal(num) {
		return Number((num / 100).toFixed(2));
	}

	return (
		<>
			<Helmet>
				<title>Pricing | MyGolfDNA.com</title>
				<meta name="description" content="Pricing and Plans"/>
			</Helmet>
			<div id="pricing-page">
				<div className="" id="video" style={{backgroundColor: '#fff', paddingTop: 40, paddingBottom: 40}}>
				<Container>
					<h1 className="text-center monument-font-black text-primary text-uppercase">Get full access for less than a single,<br/> in-person lesson</h1>
					<p className="text-black-50 text-center mb-4" style={{fontWeight: 300, fontSize: 24}}>With a Risk-Free 90-Day Money-Back Guarantee</p>
						<Row className="justify-content-center">
							<Col lg={10}>
								<div className="" style={{padding:'56.25% 0 0 0',position:'relative', height: 0, overflow: 'hidden', maxWidth: '100%'}}>
									<iframe
										src={`https://player.vimeo.com/video/${plans?.instructor.vimeoId || 827001005}?h=521d44fbfb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
										frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
										style={{position:'absolute',top:0,left:0,width:'100%',height:'100%'}} title="Why mygolfdna and pocket pro mixed for sales page"/>
								</div>
							</Col>
						</Row>
				</Container>
				</div>
				<div id="plans" className="" style={{backgroundColor: '#fafbfd', paddingTop: 40, paddingBottom: 40}}>
					<Container>
						<Row className="pt-4 justify-content-center">
							{isLoading ?
								<div className="p-4" style={{textAlign: 'center'}}> <Spinner className="text-orange" animation="border" /></div> :
								plans?.plans.map((plan, index) => (
									<Col lg={3} md={12} sm={12} key={index} className="mb-lg-0 mb-4">
										<Card className={`${plan.mostPopular ? 'border-secondary' : ''}`}>
											<Card.Body>
												<div className="d-grid gap-2">
													<Card.Title className=" text-capitalize">
														<Stack direction="horizontal" gap={3}>
															<div className="p-2">{plan.planName}</div>
															{plan.mostPopular ?
																<div className="p-2 ms-auto"><Badge bg="success" style={{fontSize: 12}}>Best Value</Badge></div>
																:
																''
															}
														</Stack>
													</Card.Title>
													<div className="d-flex align-items-center gap-1">
														<div className="h1">
															${insertDecimal(plan.amount)}
														</div>
														/<small className="">{cadenceMap[plan.cadence]}</small>
													</div>
													<PricingButton
														href={`/plans/${plan.sourceId}`}
														plan={plan.sourceId}
													>
														Get Started Now
													</PricingButton>
													<div className="pt-4 mt-4 " style={{borderTop: '2px solid #eee'}}>
														<Stack gap={2}>
															<div className="" dangerouslySetInnerHTML={{__html: plan.description}}>
															</div>
														</Stack>

													</div>




												</div>
											</Card.Body>
										</Card>
									</Col>
								))}
						</Row>
					</Container>

				</div>
				<div className="" id="testimonials" style={{backgroundColor: '#fafbfd', paddingTop: 40}}>
						<Container>
							<Row>
								<Col>
									<h3 className="text-center monument-font-black text-primary text-uppercase">Testimonials</h3>
									<p className="text-black-50 text-center mb-4" style={{fontWeight: 300, fontSize: 20}}>What Students Are Saying About Our Instruction</p>
									<PricingCarousel />
								</Col>
							</Row>
						</Container>
				</div>
				<div className="" id="faqs" style={{paddingTop: 40, paddingBottom: 40}}>
				<Container>
					<Row className="justify-content-center">
						<Col lg={8}>
								<h3 className="text-center monument-font-black text-primary text-uppercase">Frequently asked Questions</h3>
							<p className="text-black-50 text-center" style={{fontWeight: 300, fontSize: 20}}>Can’t find the answer you’re looking for?
								<br/>Feel free to reach out to us at <b>support@mygolfdna.com</b> – we’re here to help!</p>


							<Accordion flush className="pt-4">
									<Accordion.Item eventKey="4">
										<Accordion.Header>What Is MyGolfDNA?</Accordion.Header>
										<Accordion.Body>
											<p>Our membership-based virtual golf instruction portal is designed for players of all ages and skill levels.</p>
											<p>Members gain exclusive access to high-quality instructional videos and a personalized learning system that adapts to each individual’s needs. Our primary focus is on protecting your body while maximizing efficiency in your golf swing.</p>
											<p>With our virtual lesson portal, students can submit videos of their golf swings 24/7 and receive customized feedback, along with a tailored lesson plan and strategy. The number of virtual lessons and response times depend on the membership tier you choose.</p>
										</Accordion.Body>
									</Accordion.Item>
								<Accordion.Item eventKey="0">
										<Accordion.Header>What Is A Virtual Lesson?</Accordion.Header>
										<Accordion.Body>
											Virtual lessons allow you to submit a video of your swings for review. We’ll use our analysis tool to evaluate your swings or drills and provide feedback. Once your lesson is ready, you’ll receive a video on your DNA Profile page, along with a notification. Your lesson will include details on what to work on, why it’s important, and how to make improvements. We’ll also include helpful focus videos on the website to support your lesson.
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="1">
										<Accordion.Header>How Often Can I Submit Virtual Lessons?</Accordion.Header>
										<Accordion.Body>
											You can submit virtual lessons whenever you'd like, or as allowed by your membership tier. Remember, this process is designed to support you every step of the way.
										</Accordion.Body>
									</Accordion.Item>
								<Accordion.Item eventKey="2">
										<Accordion.Header>How Do I Ask Questions Specific To My Virtual Lesson?</Accordion.Header>
										<Accordion.Body>
											Go to your MyDNA Profile and find the lesson widget. There, you'll see a tab to click that opens a question box next to the lesson review you have questions about. Please note that lesson-specific questions can only be asked within 7 days of completing a lesson.
										</Accordion.Body>
									</Accordion.Item>
							<Accordion.Item eventKey="3">
										<Accordion.Header>What is PocketPRO?</Accordion.Header>
										<Accordion.Body>
											This feature gives you 24/7 access to your instructor for live virtual feedback during practice sessions, range time, or even on the course. In simple terms, it's private instruction online through platforms like Zoom or Google Meet. Think of it as a powerful supplement to your virtual lessons, helping you get even more personalized guidance.
										</Accordion.Body>
									</Accordion.Item>
							<Accordion.Item eventKey="5">
										<Accordion.Header>How Does The 90-Day Money Back Guarantee Work?</Accordion.Header>
										<Accordion.Body>
											<p>If you know me, you know I’m not a fan of subscription programs that don’t deliver. I’m guessing you feel the same way.</p>
											<p>If you sign up for a membership and have given your best effort with the virtual lessons but aren’t seeing
												the progress you expected,
												I’ll refund your entire membership at the 90-day mark.
												Just make sure to start the refund process within seven days of reaching that 90-day point by emailing
												us at support@mygolfdna.com.</p>
											</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							</Col>
					</Row>
				</Container>
				</div>
			</div>

		</>
	)
}

export default InstructorPricing;
