import {useState, useEffect} from "react";
import {useAuth} from "../../AuthUser";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import {Helmet} from "react-helmet";
import API from "../../api";
import Accordion from 'react-bootstrap/Accordion';
import Spinner from "react-bootstrap/Spinner";
import Nav from "react-bootstrap/Nav";
import NavItem from "react-bootstrap/NavItem";

function FAQs() {
	let auth = useAuth();

	const [faqs, setFAQs] = useState([])
	const [isLoading, setLoading] = useState(false)

	useEffect( () => {
		setLoading(true);
		API.get(`faq`)
			.then(data => setFAQs(data.data))
			.finally(() => setLoading(false));
	}, []);

	return (
		<>
			<Helmet>
				<title>Support Center | MyGolfDNA.com</title>
				<meta name="description" content="Frequently Asked Questions"/>
			</Helmet>

				<div className="">
					<Container>
						<div className="mt-4">
						<h1 className="text-center monument-font-black">Support Center</h1>
							<p className="text-center text-black-50">Find answers to frequently asked questions below or contact us <a href="https://shop.mygolfdna.com/pages/contact">here</a> </p>
						</div>
						{isLoading ?
							<div className="p-4" style={{textAlign: 'center'}}>
								<Spinner className="text-orange" animation="border" /></div> :
						<Row>
							<Col lg={3}>
								<Nav className="flex-column">
									{faqs?.map(faq => (
									<Nav.Link href={`#${faq.id}`}>{faq.title}</Nav.Link>
										))}
								</Nav>
							</Col>
							<Col lg={9}>

							{faqs?.map(faq => (
									<div id={faq.id} className="pt-4">
										<h5 className="monument-font-black text-primary">{faq.title}</h5>
										<p className="text-black-50">{faq.description}</p>
										<Accordion>
											{faq.faqs.map(f => (
											<Accordion.Item eventKey={f.id}>
												<Accordion.Header>{f.question}</Accordion.Header>
												<Accordion.Body>
													<div dangerouslySetInnerHTML={{__html: f.answer}}></div>
												</Accordion.Body>
											</Accordion.Item>
											))}
										</Accordion>

									</div>
						))}
							</Col>

						</Row>
						}
					</Container>
				</div>

		</>
	);
}

export default FAQs;
