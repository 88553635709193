import {useState, useEffect, useLayoutEffect} from "react";
import {useAuth} from "../../AuthUser";
import {Navigate, useParams} from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import {Helmet} from "react-helmet";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import moment from "moment";
import API from "../../api";
import Spinner from "react-bootstrap/Spinner";
import VideoComments from "../../components/VideoComments";
import Accordion from "react-bootstrap/Accordion";
import * as React from "react";
import Vimeo from "@u-wave/react-vimeo";

function Video() {
	let auth = useAuth();

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [video, setVideo] = useState([])
	const [categoryVideos, setCategoryVideos] = useState([])
	const [isLoading, setLoading] = useState(false)
	let { slug } = useParams();

	useLayoutEffect(() => {
		if(!auth.user) {
			handleShow();
		}
		setLoading(true);
		setTimeout(() => {
			API.get(`video/${slug}`)
				.then(response => {
					setVideo(response.data);
					return response.data;
				})
				.then((data) => {
					return API.get(`video/category/${data.categoryId}`)
						.then(response => setCategoryVideos(response.data))

				})
				.finally(() => setLoading(false));
		}, 0);
	}, [auth.user, slug])

	if (!slug) {
		return <Navigate to="/" replace />;
	}

	function onPause(e) {
		//console.log(e);
	}
	function onTimeChangeUpdate(e) {
		//console.log(e);
	}

	const truncate = (text, max, ending = '…') => text.length > max ? text.substr(0, max - ending.length) + ending : text;

	const rawHTML = video.description ? video.description : '';
	const title = video.title ? video.title : '';
	const vimeoId = video.vimeoId ? video.vimeoId : '';
	const releaseOnDate = video.releaseOnDate ? video.releaseOnDate : new Date();

	function SubscriptionOverlay() {
		return (
			<div className="position-absolute top-50 start-50 translate-middle" style={{zIndex: 1}}>
				<div className="text-center d-none d-md-block">
					<div><i className="fa-solid fa-lock text-orange" style={{fontSize: "3rem"}}/></div>
					<div className="h2 text-white text-center">Subscribe to watch this video</div>
					<div><Button href="/pricing" className="text-uppercase font-weight-bold text-white" variant="secondary" size="lg">Get Access Now</Button></div>
				</div>
				<div className="text-center d-sm-block d-md-none">
					<div><i className="fa-solid fa-lock text-orange" style={{fontSize: "2rem"}}/></div>
					<div className="h5 text-white text-center">Subscribe to watch this video</div>
					<div><Button style={{width: 160}} href="/pricing" className="text-uppercase font-weight-bold text-white" variant="secondary" size="sm">Get Access Now</Button></div>
				</div>
			</div>
		);
	}

	return (
		<>
			<Helmet>
				<title>{title} | MyGolfDNA.com</title>
				<meta name="description" content=""/>
			</Helmet>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header>
					<Modal.Title>Modal heading</Modal.Title>
				</Modal.Header>
				<Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
					<Button variant="primary" onClick={handleClose}>
						Save Changes
					</Button>
				</Modal.Footer>
			</Modal>
			{isLoading ? <div className="p-4" style={{textAlign: 'center'}}> <Spinner className="text-orange" animation="border" /></div> :
			<div className="">
				<Container fluid="md">
					<Row>
						<Col sm={12} md={12} lg={8} xl={8}>
							<div className="mt-3 mt-sm-4">
								{!auth.user ? <img className="img-fluid container-fluid p-0" src={video.thumbnail} alt={video.title} style={{borderRadius: "4px"}}/> :
									!auth.user.planType && !video.free ?
										<div className="img-gradient container-fluid p-0">
											<div className="test-div-inner">
											<img className="img-fluid test-image" src={video.thumbnail} alt={video.title} style={{borderRadius: "4px"}}/>
												<SubscriptionOverlay/>
											</div>
										</div>
										:
										<div className="" style={{padding: '56.25% 0 0 0', position: 'relative'}}>
											<Vimeo
												video={vimeoId}
												responsive={true}
												autoplay
												style={{
													borderRadius: "4px",
													position: 'absolute',
													top: 0,
													left: 0,
													width: '100%',
													height: '100%'
												}}
												onPause={onPause}
												onTimeUpdate={onTimeChangeUpdate}
												//start={100}
											/>

										</div>
									}
								</div>
							<div className="mt-2">
							<div className="h4">{title}</div>

							<div className="mt-3 p-3" style={{fontSize: 12, whiteSpace: "pre-wrap", backgroundColor: "rgba(0, 0, 0, 0.05)", borderRadius: 4}}>
								<div className="d-inline mt-0 pt-0" dangerouslySetInnerHTML={{__html: rawHTML}}/>
							</div>
								<div className="">
									<Accordion className="pt-4">
										<Accordion.Item eventKey="4">
											<Accordion.Header  style={{backgroundColor: "rgba(0, 0, 0, 0.05)"}} >Comments</Accordion.Header>
											<Accordion.Body>
												<VideoComments/>
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</div>
							</div>
						</Col>
					<Col>
						<div className="mt-4">
							{categoryVideos[0]?.videos.map((video, index) => {
								return <a key={index} style={{textDecoration: "none"}} href={`/videos/${video.slug}`} title={video.title}>
									<div className="d-flex flex-row mb-2">
									<div className="d-inline-block px-2">
										<div className={!auth.user.planType && !video.free ? 'img-gradient' : 'position-relative'}>
											<div style={{borderRadius: "4px", width: "150px", minHeight: "84px"}}>
											<img src={video.thumbnail} alt={video.title} style={{borderRadius: "4px", maxWidth: "150px", minHeight: "84px"}}/>
											{!auth.user.planType && !video.free ?
												<div className="position-absolute top-50 start-50 translate-middle" style={{zIndex: 10}}>
													<i className="fa-solid fa-lock" style={{color: "#ff5b25", fontSize: "1.5rem"}}/>

												</div>
												: ''
											}
											<div className="position-absolute bottom-0 end-0 text-white p-1 font-weight-bold" style={{zIndex: 10, fontSize: 10}}>
												<span className="text-white-50">{!auth.user.planType && !video.free ? ' Premium' : ''}</span>
											</div>
										</div>
										</div>
									</div>
									<div className="d-inline-block align-top px-2"><h6 style={{textWrap: 'wrap', fontSize: "14px"}} className="">{truncate(video.title,60, '...')}</h6>
										<p className="mb-1" style={{fontSize: 12}}>{video.Category.title}</p>
									</div>
								</div>
								</a>
							})}
						</div>
					</Col>
					</Row>
				</Container>
			</div>
			}
		</>
	);
}

export default Video;
