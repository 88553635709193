import React from "react";
import {useNavigate, Link, Navigate, useLocation} from "react-router-dom";
import {
	Button,
	FormGroup,
	Form,
	Container,
	Row,
	Col,
} from "react-bootstrap";
import { Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../AuthUser";
import API from "../../api";
import {Helmet} from "react-helmet";
//import {Helmet} from "react-helmet";

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Signup = () => {
	const { user, setUser } = useAuth();
	const navigate = useNavigate();
	let query = useQuery();

	if (user) {
		return <Navigate to="/dna-profile" replace />;
	}

	const notify = (message) =>
		toast.error(message, {
			position: toast.POSITION.TOP_RIGHT,
		});

	return (
		<Formik
			initialValues={{ firstName: "", lastName: "", email: "", password: "", confirmPassword: "" }}
			validate={(values) => {
				const errors = {};
				if (!values.email) {
					errors.email = "Required";
				} else if (
					!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
				) {
					errors.email = "Invalid email address";
				}
				if (!values.password) {
					errors.password = "Required";
				}
				if (!values.confirmPassword) {
					errors.confirmPassword = "Passwords must match";
				}
				if (values.password && values.confirmPassword) {
					if (values.password !== values.confirmPassword) {
						errors.confirmPassword = "Passwords must match";
						errors.password = "Passwords must match";
					}
				}
				if (!values.firstName) {
					errors.firstName = "Required";
				}
				if (!values.lastName) {
					errors.lastName = "Required";
				}

				return errors;
			}}
			onSubmit={(values, { setSubmitting, setErrors }) => {
				setTimeout(() => {
					API.post(`auth/signup?r=${query.get('r')}`, {
						email: values.email,
						password: values.password,
						confirmPassword: values.confirmPassword,
						firstName: values.firstName,
						lastName: values.lastName,
					})
						.then((res) => {
							setUser(res.data);
							if (query.get('r') && res.data.canSubmitLesson) {
								return navigate(`/upload-videos?r=${query.get('r')}`, { replace: true });
							}
							navigate(`${query.get('r') ? query.get('r') : '/videos'}`, { replace: true });
							setSubmitting(false);
						})
						.catch((error) => {
							setSubmitting(false);
							if (error.response) {
								notify(error.response.data.message);
							}
							if (
								error.response &&
								error.response.data.errors &&
								error.response.data.errors.length
							) {
								const result = error.response.data.errors.reduce(function (
										map,
										obj
									) {
										map[obj.property] = obj.message;
										return map;
									},
									{});
								setErrors(result);
							}
						});
				}, 400);
			}}
		>
			{({
					values,
					errors,
					touched,
					handleChange,
					handleSubmit,
					isSubmitting,
				}) => (
				<>
					<Helmet>
						<title>Signup | MyGolfDNA.com</title>
						<meta name="description" content="signup for an account"/>
					</Helmet>
					<Container>
						<Row>
							<Col sm={12} md={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }} xl={{ span: 4, offset: 4 }}>
								<h2 className="text-white text-center mt-5">Sign up</h2>
							<Form noValidate className="mt-5 form-group" onSubmit={handleSubmit}>
								<Row>
									<Col sm={12} md={6}>
										<Form.Group className="form-floating mb-3">
											<Form.Control
												className="bg-dark border-light border-opacity-25 text-light"
												placeholder="First name*"
												type="text"
												name="firstName"
												id="firstname"
												onChange={handleChange}
												value={values.firstName}
												isInvalid={errors.firstName && touched.firstName}
												isValid={!errors.firstName && touched.firstName}
											/>
											<Form.Label className="text-white" htmlFor="firstname">First name</Form.Label>
											<div className="invalid-feedback">{errors.firstName}</div>
										</Form.Group>
									</Col>
									<Col sm={12} md={6}>
										<Form.Group className="form-floating mb-3">
											<Form.Control
												className="bg-dark border-light border-opacity-25 text-light"
												placeholder="Last name*"
												type="text"
												name="lastName"
												id="lastname"
												onChange={handleChange}
												value={values.lastName}
												isInvalid={errors.lastName && touched.lastName}
												isValid={!errors.lastName && touched.lastName}
											/>
											<Form.Label className="text-white" htmlFor="lastname">Last name</Form.Label>
											<div className="invalid-feedback">{errors.lastName}</div>
										</Form.Group>
									</Col>
								</Row>
									<Form.Group className="form-floating mb-3">
										<Form.Control
											className="bg-dark border-light border-opacity-25 text-light"
											placeholder="Email address*"
											type="email"
											name="email"
											id="email"
											onChange={handleChange}
											value={values.email}
											isInvalid={errors.email && touched.email}
											isValid={!errors.email && touched.email}
										/>
										<Form.Label className="text-white" htmlFor="email">Email address</Form.Label>
										<div className="invalid-feedback">{errors.email}</div>
									</Form.Group>
									<Form.Group className="form-floating mb-3">
										<Form.Control
											className="bg-dark border-light border-opacity-25 text-white"
											placeholder="Password*"
											type="password"
											name="password"
											id="password"
											onChange={handleChange}
											value={values.password}
											isInvalid={errors.password && touched.password}
										/>
										<Form.Label className="text-white" htmlFor="password">Your password</Form.Label>
										<div className="invalid-feedback">{errors.password}</div>
									</Form.Group>
								<Form.Group className="form-floating mb-3">
									<Form.Control
										className="bg-dark border-light border-opacity-25 text-light"
										placeholder="Confirm password"
										type="password"
										name="confirmPassword"
										id="confirmpassword"
										onChange={handleChange}
										value={values.confirmPassword}
										isInvalid={errors.confirmPassword && touched.confirmPassword}
									/>
									<Form.Label className="text-white" htmlFor="confirmpassword">Confirm password</Form.Label>
									<div className="invalid-feedback">{errors.confirmPassword}</div>
								</Form.Group>
									<FormGroup className="mt-5">
										<div className="d-grid gap-4">
										<Button variant="secondary" size="lg" type="submit" disabled={isSubmitting}>
											{isSubmitting ? (
												<>
													<span
														className="spinner-border spinner-border-sm"
														role="status"
														aria-hidden={true}
													/>{" "}
												</>
											) : (
												"Sign up"
											)}
										</Button>
											{query.get('r') ? '' : <Button variant="link" as={Link} to="/signin" size="sm" className="text-light text-opacity-75">Already have an account? Sign in</Button>}
										</div>
										</FormGroup>
						</Form>
							</Col>
						</Row>
						<ToastContainer />
					</Container>
				</>
			)}
		</Formik>
	);
};

export default Signup;
