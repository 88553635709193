import * as React from "react";
import { Navigate} from "react-router-dom";
import { useAuth } from "./AuthUser";

import "./assets/css/Home.css"
import {Container, Row, Col} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {Helmet} from "react-helmet";
import Signup from "./components/Signup";
import {SwiperInstructors} from "./components/SwiperInstructors";
import PricingCarousel from "./components/PricingCarousel";
function Home() {
	let auth = useAuth();

	if (auth.user) {
		return <Navigate to="/dna-profile" replace />;
	}

	return (
		<>
			<Helmet>
				<title>Transform Your Game Today | MyGolfDNA.com</title>
				<meta name="description" content="
A membership-based online golf instruction platform for all skill levels, focusing on body protection and maximizing swing efficiency."/>
			</Helmet>
			<div className="position-relative overflow-hidden home-bg">
				<Container>
					<Row>
				<div className="col-md-7 p-4 p-lg-5 my-0 my-md-5">
					<h1 className="home-heading lh-2 mb-4 text-white text-uppercase"> Transform Your<br/><span className="text-secondary">Game Today</span> </h1>
					<p className="lead fw-normal text-white" style={{fontSize: 26}}>Uncover the Secrets That Golf Instructors Won’t Share.
				</p>
					<ul className="list-unstyled text-white mb-4">
						<li><i className="bi bi-check-circle text-success"/> <span className="text-white"><u><b>Unlimited</b></u></span> Coaching for the Price of <span className="text-white"><u><b>One</b></u></span> Lesson </li>
						<li><i className="bi bi-check-circle text-success"/> 1 on 1 Personalized Feedback</li>
						<li><i className="bi bi-check-circle text-success"/> Access to 300+ Golf Instruction Videos</li>
						<li><i className="bi bi-check-circle text-success"/> Practice Plans Built and Perfectly Matched to Your DNA</li>
					</ul>
					<Button variant="secondary" size="lg" href="/pricing" className="text-white btn-secondary font-weight-bold visually-hidden">GET ACCESS NOW</Button>
				</div>

					<div className="col p-0 p-lg-4 mb-4">
						<Signup/>
					</div>
					</Row>
				</Container>
			</div>
			<div  className="pt-4 pb-4" style={{backgroundColor: "#f8f9faa1"}}>

							<div className="pt-4 pb-4">
								<h2 className="text-center monument-font-black text-primary mb-3 text-uppercase">Our <span className="text-secondary">3-Step System</span> Has <u>Shocked</u> Golfers Worldwide





								</h2>
							</div>

			<Container>
				<Row className="align-items-center" >

				<Col md={12} sm={12} lg={7}>
					<div className="" id="how-it-works">

					<div className="d-flex flex-row mb-4">

					<div className="d-inline-block px-2">
						<i className="bi bi-1-circle-fill text-secondary h1"/>
					</div>
					<div className="d-inline-block align-top px-2">
						<h3 className="monument-font">IDENTIFY YOUR GOLF DNA</h3>
						<p>Improvement starts with the identifying the entire state of your game with your instructor. Establishing your profile helps us prioritize your focus.</p>
					</div>
					</div>
					<div className="d-flex flex-row mb-4">

					<div className="d-inline-block px-2">
						<i className="bi bi-2-circle-fill text-secondary h1"/>
					</div>
					<div className="d-inline-block align-top px-2">
						<h3 className="monument-font">BUILD YOUR PLAN</h3>
						<p>Your instructor will help you set realistic goals and create a plan that fits your lifestyle. We will constantly fine tune your plan until it is dialed in perfectly.</p>
					</div>
					</div>
					<div className="d-flex flex-row">

					<div className="d-inline-block px-2">
						<i className="bi bi-3-circle-fill text-secondary h1"/>
					</div>
					<div className="d-inline-block align-top px-2">
						<h3 className="monument-font">GET TO WORK</h3>
						<p>You will have endless support on your adventure. We are pretty crazy about seeing you succeed, you'll see.</p>
					</div>
					</div>
				</div>
				</Col>
				<Col lg={5} md={12} sm={12}>
					<div className="" style={{padding:'56.25% 0 0 0',position:'relative'}}>
						<iframe
	src="https://player.vimeo.com/video/962579932?h=521d44fbfb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
	frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
	style={{position:'absolute',top:0,left:0,width:'100%',height:'100%'}} title="How it works"/>
					</div>
				</Col>
			</Row>
			</Container>
			</div>
			<div className="" id="testimonials" style={{backgroundColor: '#fafbfd', paddingTop: 40}}>
				<Container>
					<Row>
						<Col>
							<h3 className="text-center monument-font-black text-primary text-uppercase">Testimonials</h3>
							<p className="text-black-50 text-center mb-4" style={{fontWeight: 300, fontSize: 24}}>What Students Are Saying About Our Instruction</p>
							<PricingCarousel />
						</Col>
					</Row>
				</Container>
			</div>
			<div className="p-4 mb-4">
				<Container>
					<Row>
						<Col>
				<div className="text-center">
					<Button href="/pricing" variant="secondary" size="lg" style={{fontSize: 30, color: "#ffffff", width: 300}} className="">Get Access Now</Button>
				</div>
						</Col>
					</Row>
				</Container>
			</div>

		</>
	);
}

export default Home;
