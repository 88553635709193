import * as React from "react";
import {useAuth} from "../AuthUser";
import {Link, Navigate, useLocation, useNavigate} from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import {Helmet} from "react-helmet";
import VideoUpload from "./VideoUpload";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {useEffect, useState} from "react";
import API from "../api";

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}
function UploadVideos() {
	let navigate = useNavigate();
	let auth = useAuth();

	let query = useQuery();

	const [show, setShow] = useState(false);
	const handleClose = () => {
		navigate('/dna-profile', { replace: true })
	}
	const handleShow = () => setShow(true);

	const handleNavigate = () => {
		navigate('/pricing', { replace: true })
	}

	useEffect( () => {

		if (auth.user.lessonCountRemaining === 0) {
			handleShow();
		}
	}, []);


	if (!auth.user) {
		return <Navigate to="/dna-profile" replace />;
	}


	return (
		<>
			<Helmet>
				<title>Upload Lesson Videos | mygolfdna.com</title>
				<meta name="description" content="upload lesson videos"/>
			</Helmet>
			<div className="mt-3">
				<Container>
					<Row>
						<Col sm={12} md={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }} xl={{ span: 8, offset: 2 }}>
							<div className="hstack gap-3">
								<div className="h3">Upload Lesson Videos</div>
							</div>
							<hr/>
							<VideoUpload
							redirectTo="/dna-profile"
							instructorId={query.get('r')}
							/>
						</Col>
					</Row>
				</Container>
			</div>
			<Modal
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
			>
				<Modal.Body>
					<p>Oops! Looks like you don't have any swing reviews available!</p>
					<p>Get your swing analyzed twice a month for less than the cost of two dozen golf balls.</p>
					<div className="text-center">
						<Button variant="success" size="lg" onClick={handleNavigate}>
							Learn More
						</Button><br/><br/>
						<Button variant="link" as={Link} to="/dna-profile" size="sm" className="text-black">No Thanks, I Don't Want To Get Better</Button>

					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}

export default UploadVideos;
