import React, {useEffect} from "react";
import {
    Button,
    Row,
    Col,
    Container,
} from "react-bootstrap";
import {Helmet} from "react-helmet";
import {useAuth} from "../AuthUser";
import API from "../api";

const Welcome = () => {
    const { setUser, user } = useAuth();
    useEffect(() => {
        API.get(`/accounts/me`)
            .then(response => setUser(response.data))
    }, [])
    return (
        <>
            <Helmet>
                <title>Welcome! | MyGolfDNA.com</title>
                <meta name="description" content="welcome to mygolfdna"/>
            </Helmet>
            <div className="mt-4">
                <Container>

                    <Row>
                        <div className="text-center h1 text-uppercase monument-font">Welcome to MyGolfDNA!</div>
                        <Row className="justify-content-center">
                            <Col lg={5} md={12} sm={12}>
                                <div className="" style={{padding:'56.25% 0 0 0',position:'relative'}}>
                                    <iframe
                                        src="https://player.vimeo.com/video/1029482573?h=51c8710145&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                        frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                                        style={{position:'absolute',top:0,left:0,width:'100%',height:'100%'}}
                                        title="Welcome New Member - MyGolfDNA"/>
                                </div>
                            </Col>
                        </Row>
                        <Col sm={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} xl={{ span: 6, offset: 3 }}>
                            <div className="mt-3 d-grid gap-3 text-center">
                                <p className="text-center">
                                    Ready to take your golf swing to the next level? Watch the video above and start improving today!
                                </p>
                                <div className="text-center">
                                    <Button variant="secondary" size="lg" href="/pricing/instructor/15" className="text-white font-weight-bold">Unlock Premium Features Now</Button>
                                    <br/>
                                    <div className="mt-3"><a className="small text-black-50 " href="/dna-profile">Continue as free user instead</a></div>
                                </div>
                            </div>


                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Welcome;
