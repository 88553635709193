import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import axios from 'axios';
import api from "../api";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import moment from "moment/moment";
import {useNavigate} from "react-router-dom"; // or any other library for fetching data

const DynamicSearch = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const navigate = useNavigate();
    const handleSearch = async (query) => {
        const response = await api.get(`/video/search?q=${query}`);
        setOptions(response.data);
        console.log('videos: ', response.data)
        setIsLoading(false);
    };

    // Bypass client-side filtering by returning `true`. Results are already
    // filtered by the search endpoint, so no need to do it again.
    const filterBy = () => true;
    const truncate = (text, max, ending = '…') => text.length > max ? text.substr(0, max - ending.length) + ending : text;

    return (
        <AsyncTypeahead
            onChange={(selected) => {
                if (!selected.length){
                } else {
                    navigate(`/videos/${selected[0]?.slug}`)
                }
            }}
            style={{maxWidth: '500px', zIndex: 1010}}
            filterBy={filterBy}
            id="async-example"
            isLoading={isLoading}
            labelKey="title"
            minLength={3}
            onSearch={handleSearch}
            options={options}
            placeholder="Search for a video..."
            renderMenuItemChildren={(video) => (
                <>

                        <div className="d-flex flex-row mb-2">
                            <div className="d-inline-block px-2">
                                <div className='position-relative'>
                                    <div style={{borderRadius: "4px", width: "150px", minHeight: "84px"}}>
                                        <img src={video.thumbnail} alt={video.title} style={{borderRadius: "4px", maxWidth: "150px", minHeight: "84px"}}/>
                                    </div>
                                </div>
                            </div>
                            <div className="d-inline-block align-top px-2"><h6 style={{textWrap: 'wrap', fontSize: "14px"}} className="">{truncate(video.title,60, '...')}</h6>
                            </div>
                        </div>
                </>
            )}
        />
    );
};

export default DynamicSearch;
