import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import moment from "moment";
import 'swiper/swiper-bundle.css';
import {useAuth} from "../AuthUser";

SwiperCore.use([Navigation]);

const truncate = (text, max, ending = '…') => text.length > max ? text.substr(0, max - ending.length) + ending : text;

export const SwiperVideos = ({ videos = []}) => {
	const auth = useAuth();
	const slides = [];

	for (let i = 0; i < videos.length; i += 1) {
		slides.push(
			<SwiperSlide key={`slide-${videos[i]}${Math.random()}`} tag="li" style={{ listStyle: 'none' }}>
				<a style={{textDecoration: "none"}} href={`/videos/${videos[i]['slug']}`} title={videos[i]['title']}>
					<div className="card border-0">
						<div className={!auth.user.planType && !videos[i]['free'] ? 'img-gradient' : 'position-relative'}>
						<img style={{
							borderBottomRightRadius: "4px",
							borderBottomLeftRadius: "4px",
							//minHeight: "116px"
						}}
								 src={videos[i]['thumbnail']}
								 className="card-img-top"
								 alt={videos[i]['title']}/>
							{!auth.user.planType && !videos[i]['free'] ?
								<div className="position-absolute top-50 start-50 translate-middle" style={{zIndex: 10}}>
									<i className="fa-solid fa-lock" style={{color: "#ff5b25", fontSize: "1.5rem"}}/>
								</div>
								: ''
							}
						</div>
						<div className="card-body p-0 pt-2">
						<h6 className="card-title" style={{textWrap: 'wrap', fontSize: "14px"}}>{truncate(videos[i]['title'],60, '...')}</h6>
						<p className="card-subtitle mb-1" style={{fontSize: 12}}>{videos[i]['Category']['title']}</p>
					</div>
					</div>
				</a>
			</SwiperSlide>
		);
	}

	return (
		<React.Fragment>
			<Swiper
				breakpoints={{
					100: {
						//width: 640,
						slidesPerView: 2,
					},
					640: {
						//width: 640,
						slidesPerView: 2,
					},
					// when window width is >= 768px
					768: {
						//width: 640,
						slidesPerView: 3,
					},
					// when window width is >= 1024px
					1024: {
						//width: 768,
						slidesPerView: 6,
					}
				}}
				id="main"
				//width="480"
				navigation
				spaceBetween={10}
				slidesPerView={1}
			>
				{slides}
			</Swiper>
		</React.Fragment>
	);
};
