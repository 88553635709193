import { Container, Row, Col } from 'react-bootstrap';

function PricingCarousel() {
	return (
		<>
			<div id="pricing-carousel" className="">
		<Container>
			<Row className="justify-content-center text-center mb-lg-4 mb-md-4 mb-sm-0">
				<Col sm={12} lg={4} md={6}>
					<figure className="text-center">
						<div className="ratings text-orange">
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
						</div>
						<blockquote className="blockquote">
							<p>Hands down this guy is the best Communicator on the Golf Swing since the late Harvey Penick !!! We need more articulate swing coaches like this.</p>
						</blockquote>
						<figcaption className="blockquote-footer">
							Tripp
						</figcaption>
					</figure>
				</Col>
				<Col sm={12} lg={4} md={6}>
					<figure className="text-center">
						<div className="ratings text-orange">
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
						</div>
						<blockquote className="blockquote">
							<p>The "pocketpro" format is a game changer. This differentiates you from the instructional platforms, and it makes a world of difference. That, along with the initial assessment helped me to feel much more connected to you as my instructor.</p>
						</blockquote>
						<figcaption className="blockquote-footer">
							Duncan
						</figcaption>
					</figure>
				</Col>
				<Col sm={12} lg={4} md={6}>
					<figure className="text-center">
						<div className="ratings text-orange">
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
						</div>
						<blockquote className="blockquote">
							<p>You are at the vanguard of new teaching of technical swing movements, turning traditional teaching instruction on its heels.</p>
						</blockquote>
						<figcaption className="blockquote-footer">
							Harold
						</figcaption>
					</figure>
					<p></p>
					<p className="small text-white-50 fst-italic"></p>
				</Col>
			</Row>
			<Row className="justify-content-center text-center mb-lg-4 mb-md-4 mb-sm-0">
				<Col sm={12} lg={4} md={6}>
					<figure className="text-center">
						<div className="ratings text-orange">
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
						</div>
						<blockquote className="blockquote">
							<p>I was a 6 HCI in Houston, if I had the benefit of this information and access to this instructor's information then I could have a 1 or below. Take note from a 40 year golf fanatic. This man is a top notch instructor, listen to him.</p>
						</blockquote>
						<figcaption className="blockquote-footer">
							Leo
						</figcaption>
					</figure>
				</Col>
				<Col sm={12} lg={4} md={6}>
					<figure className="text-center">
						<div className="ratings text-orange">
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
						</div>
						<blockquote className="blockquote">
							<p>I picked up golf 10 months ago, I've watched hundreds of video and took maybe 5 lessons. You sir are teaching EXACTLY what I hoped my lessons would have covered. This is the best How To training of the correct movements for the right reasons.</p>
						</blockquote>
						<figcaption className="blockquote-footer">
							James
						</figcaption>
					</figure>
				</Col>
				<Col sm={12} lg={4} md={6}>
					<figure className="text-center">
						<div className="ratings text-orange">
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
						</div>
						<blockquote className="blockquote">
							<p>Best instruction on the planet! Thank you! Finally! Great instruction presented with honest expectations and work required. So tired of hearing "do this for a bucket of balls and you'll bet great!</p>
						</blockquote>
						<figcaption className="blockquote-footer">
							Barry
						</figcaption>
					</figure>
					<p></p>
					<p className="small text-white-50 fst-italic"></p>
				</Col>
			</Row>
			<Row className="justify-content-center text-center mb-4">
				<Col sm={12} lg={4} md={6}>
					<figure className="text-center">
						<div className="ratings text-orange">
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
						</div>
						<blockquote className="blockquote">
							<p>You are the only instructor that shows us how to swing a golf club STEP by STEP. Thanks I learned a lot.</p>
						</blockquote>
						<figcaption className="blockquote-footer">
							Raymond
						</figcaption>
					</figure>
				</Col>
				<Col sm={12} lg={4} md={6}>
					<figure className="text-center">
						<div className="ratings text-orange">
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
						</div>
						<blockquote className="blockquote">
							<p>Many thanks again & love your method of teaching these very basic & essential core elements of the Golf swing.</p>
						</blockquote>
						<figcaption className="blockquote-footer">
							Daniel
						</figcaption>
					</figure>
				</Col>
				<Col sm={12} lg={4} md={6}>
					<figure className="text-center">
						<div className="ratings text-orange">
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
							<i className="bi bi-star-fill"></i>
						</div>
						<blockquote className="blockquote">
							<p>Finally!!! A pro who explains like you’re teaching one on one!!! Thank you!!! Where have you been?</p>
						</blockquote>
						<figcaption className="blockquote-footer">
							RJ
						</figcaption>
					</figure>
				</Col>
			</Row>
		</Container>
			</div>
		</>
	)
}

export default PricingCarousel;
