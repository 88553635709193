import * as React from "react";
import {useNavigate} from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {Helmet} from "react-helmet";
import Badge from "react-bootstrap/Badge";
import API from "../../api";
import {useEffect, useState} from "react";
import moment from "moment";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import LoadingDiv from "../../components/LoadingDiv";
import {FilterMatchMode} from "primereact/api";
import {IconField} from "primereact/iconfield";
import {InputIcon} from "primereact/inputicon";
import {InputText} from "primereact/inputtext";


function Lessons() {
	let navigate = useNavigate();

	const [videos, setVideos] = useState([])
	const [isLoading, setLoading] = useState(false)
	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		title: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
	});
	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const onGlobalFilterChange = (e) => {
		const value = e.target.value;
		let _filters = { ...filters };

		_filters['global'].value = value;

		setFilters(_filters);
		setGlobalFilterValue(value);
	};

	const renderHeader = () => {
		return (
			<div className="flex justify-content-end">
				<IconField iconPosition="left">
					<InputIcon className="pi pi-search" />
					<InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
				</IconField>
			</div>
		);
	};

	useEffect(() => {
		setLoading(true);
		API.get("admin/videos")
			.then(response => setVideos(response.data))
			.finally(() => setLoading(false));
	}, [])

	const onRowSelect = (event) => {
		navigate(`/admin/videos/${event.data.id}`)
	};

	const thumbnailTemplate = (video) => {
		return <img src={video.thumbnail} alt={video.title} width={100} />
	}

	const activeTemplate = (video) => {
		if (video.active) {
			return <Badge bg="success">Active</Badge>
		}
		if (!video.active) {
			return <Badge bg="danger">Disabled</Badge>
		}
	}

	const freeTemplate = (video) => {
		if (video.free) {
			return <span>Free</span>
		}
		if (!video.free) {
			return <span><i className="bi bi-lock"/> Premium</span>
		}
	}

	const releaseOnDateTemplate = (video) => {
		return moment(video.releaseOnDate).format('YYYY-MM-DD');
	}

	const createdDateTemplate = (video) => {
		return moment(video.created_at).format('YYYY-MM-DD');
	}

	const header = renderHeader();

	return (
		<>
			<Helmet>
				<title>Videos | MyGolfDNA.com</title>
				<meta name="description" content="Videos"/>
			</Helmet>
			<div className="mt-4">
				<Row>
					<Col>
						<h2>Videos</h2>
						{isLoading ? <LoadingDiv/> :
							<div className="card">
								<DataTable
									value={videos}
									paginator
									rows={200}
									rowsPerPageOptions={[10, 25, 50, 200]}
									tableStyle={{minWidth: '50rem'}}
									selectionMode="single"
									onRowSelect={onRowSelect}
									filters={filters}
									filterDisplay="row"
									globalFilterFields={['title']} header={header} emptyMessage="No videos found."
								>
									<Column field="thumbnail" header="Thumbnail" body={thumbnailTemplate}/>
									<Column field="title" header="Title"/>
									<Column field="active" sortable header="Status" body={activeTemplate}/>
									<Column field="free" sortable header="Free" body={freeTemplate}/>
									<Column field="Category.title" sortable header="Category"/>
									<Column field="orderBy" sortable header="Order By"/>
									<Column field="releaseOnDate" sortable header="Release On Date" body={releaseOnDateTemplate}/>
									<Column field="created_at" sortable header="Created Date" body={createdDateTemplate}/>
								</DataTable>
							</div>
						}
					</Col>
				</Row>
			</div>
		</>
	);
}

export default Lessons;
