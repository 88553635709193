import {useAuth} from "../AuthUser";
import {Button} from "react-bootstrap";

const PricingButton = ({ href, plan }) => {
	let auth = useAuth();

	if (auth.user.planId === plan) {
		return <Button href={href} variant="light" disabled>Current Plan</Button>
	}
	if (auth.user && auth.user.planId !== plan) {
		return <Button href={href} variant="warning" >Upgrade</Button>
	}
	return (<Button href={`/signup?r=${href}`} variant="warning">Get Started</Button>)
};

export default PricingButton;

